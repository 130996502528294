import React, { useState, useRef, useEffect } from "react";
import ChatHistory from "./ChatHistory";
import { SEND_MESSAGE } from "../mutations";
import { SEND_MESSAGE_FOR_TOKO } from "../mutations";
import { useMutation } from "@apollo/client";

const ChatComponent = ({ tourId, location, purpose }) => {
  // State variables
  const [textInput, setTextInput] = useState("");
  const [messagesForChatHistory, setMessagesForChatHistory] = useState(
    purpose != "tayne"
      ? [
          {
            text: "hey there! welcome to talky mappy",
            author: "system",
            created_at: "2023-08-13T10:00:00Z",
          },
          {
            text: 'tell me what kind of tour you want OR you can just press "start tour" and we\'ll build you something standard',
            author: "system",
            created_at: "2023-08-13T10:00:00Z",
          },
        ]
      : [
          {
            text: "let's work on your app. say things please.",
            author: "system",
            created_at: "2023-08-13T10:00:00Z",
          },
        ]
  );

  const textareaRef = useRef(null);
  const [sendMessagesMutation, { loading: sendMessageLoading }] =
    useMutation(SEND_MESSAGE);
  const [sendMessagesForTokoMutation, { loading: sendMessageForTokoLoading }] =
    useMutation(SEND_MESSAGE_FOR_TOKO);

  // Adjust textarea height based on content
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [textInput]);

  // Handlers
  const handlePromptChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleSendMessage = () => {
    if (!textInput.trim() || sendMessageLoading) {
      return;
    }
    setMessagesForChatHistory((prevMessages) => [
      ...prevMessages,
      {
        text: textInput,
        author: "user",
        created_at: new Date().toISOString(),
      },
    ]);
    setTextInput("");
    if (purpose != "tayne") {
      console.log("not tayne");
      sendMessagesMutation({
        variables: {
          tourId: tourId,
          message: textInput,
          lat: parseFloat(location.lat),
          lon: parseFloat(location.lon),
        },
      })
        .then((response) => {
          console.log("after send message", response);
          if (
            response.data.sendMessage.result === "fail/spots_item_not_found"
          ) {
            alert("Send message failed because spots item not found!");
            return;
          }
          setMessagesForChatHistory((prevMessages) => [
            ...prevMessages,
            {
              text: response.data.sendMessage.response,
              author: "system",
              created_at: new Date().toISOString(),
            },
          ]);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          alert("Failed to send message.");
        });
    } else {
      console.log("its tayne");
      sendMessagesForTokoMutation({
        variables: {
          message: textInput,
        },
      })
        .then((response) => {
          console.log("after send message", response);
          if (
            response.data.sendMessageForToko.result ===
            "fail/spots_item_not_found"
          ) {
            alert("Send message failed because spots item not found!");
            return;
          }
          setMessagesForChatHistory((prevMessages) => [
            ...prevMessages,
            {
              text: response.data.sendMessageForToko.response,
              author: "system",
              created_at: new Date().toISOString(),
            },
          ]);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          alert("Failed to send message.");
        });
    }
  };

  const handleRecord = () => {
    alert("Not implemented!");
  };

  // Styles
  const textareaStyle = {
    width: "calc(100% - 80px)",
    padding: "10px",
    marginBottom: "10px",
    marginLeft: "10px",
    border: "1px solid #ccc",
    borderRadius: "3px",
    backgroundColor: "#f0f0f0",
    resize: "none",
    overflow: "hidden",
    minHeight: "40px",
    maxHeight: "180px",
  };

  const lightGrayColor = "#8A8888";

  const buttonStyle = {
    padding: "10px 20px",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
    marginLeft: "10px",
    backgroundColor: "white",
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
    color: lightGrayColor,
  };

  const sendButtonStyle = {
    ...buttonStyle,
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    background: "transparent",
    opacity: sendMessageLoading ? 0.5 : 1,
    cursor: sendMessageLoading ? "not-allowed" : "pointer",
  };

  const recordButtonStyle = {
    ...buttonStyle,
    marginLeft: "10px",
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  };

  return (
    <div>
      <ChatHistory messages={messagesForChatHistory} />
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginBottom: "20px",
        }}
      >
        <textarea
          ref={textareaRef}
          value={textInput}
          onChange={handlePromptChange}
          style={textareaStyle}
          rows="1"
        />
        <button onClick={handleRecord} style={recordButtonStyle}>
          <svg
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="8" fill="#FF0000" />
          </svg>
        </button>
        <button
          onClick={handleSendMessage}
          style={sendButtonStyle}
          disabled={sendMessageLoading}
        >
          {sendMessageLoading ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              style={{ animation: "spin 1s linear infinite" }}
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                fill="none"
                stroke="#8A8888"
                strokeWidth="2"
                strokeDasharray="30 60"
              />
            </svg>
          ) : (
            <svg
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 2L11 13" />
              <path d="M22 2L15 22L11 13L2 9L22 2Z" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default ChatComponent;
