import React, { useState, useCallback, useEffect, useRef } from "react";
import ChatHistory from "./components/ChatHistory";
import ChatComponent from "./components/ChatComponent";

const Au = ({ isLoggedIn }) => {
  const [messagesForChatHistory, setMessagesForChatHistory] = useState([
    {
      text: "hey there! welcome to talky mappy",
      author: "system",
      created_at: "2023-08-13T10:00:00Z",
    },
    {
      text: 'tell me what kind of tour you want OR you can just press "start tour" and we\'ll build you something standard',
      author: "system",
      created_at: "2023-08-13T10:00:00Z",
    },
  ]);
  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px" }}
      >
        <span style={{ margin: "0 15px" }}>chat</span>
        <span style={{ margin: "0 15px" }}>rate</span>
        <span style={{ margin: "0 15px" }}>clowns</span>
      </div>
      {/* {" "} */}
      {/* <ChatHistory messages={messagesForChatHistory} /> */}
      <ChatComponent purpose={"tayne"} />
    </div>
  );
};

export default Au;
